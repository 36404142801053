// extracted by mini-css-extract-plugin
export var bannerContainer = "PostOnLinkedIn-module--bannerContainer--TBPie";
export var bottomLeftDesign = "PostOnLinkedIn-module--bottomLeftDesign--5-y5e";
export var cameraContainer = "PostOnLinkedIn-module--cameraContainer--ZnG6R";
export var canMasterLogo = "PostOnLinkedIn-module--canMasterLogo--Jr+5O";
export var captureButton = "PostOnLinkedIn-module--captureButton--SSrZQ";
export var container = "PostOnLinkedIn-module--container--drLXw";
export var content = "PostOnLinkedIn-module--content--iynd3";
export var contentContainer = "PostOnLinkedIn-module--contentContainer--dYlnf";
export var extraContent = "PostOnLinkedIn-module--extraContent--yQKUu";
export var photo = "PostOnLinkedIn-module--photo--l0VIB";
export var photoButton = "PostOnLinkedIn-module--photoButton--JG+3f";
export var photoPreview = "PostOnLinkedIn-module--photoPreview--pO+te";
export var postButton = "PostOnLinkedIn-module--postButton--WmQ3z";
export var reloadButton = "PostOnLinkedIn-module--reloadButton--rV3pR";
export var retakeButton = "PostOnLinkedIn-module--retakeButton--V7kCT";
export var rotate = "PostOnLinkedIn-module--rotate--bzw7p";
export var success = "PostOnLinkedIn-module--success--zsizG";
export var textArea = "PostOnLinkedIn-module--textArea--4T2Nx";
export var textAreaContainer = "PostOnLinkedIn-module--textAreaContainer--oYqtL";
export var topRightDesign = "PostOnLinkedIn-module--topRightDesign--IWcHd";
export var video = "PostOnLinkedIn-module--video--3PJZq";
export var viewPostButton = "PostOnLinkedIn-module--viewPostButton--5F0ZN";
import React, { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './PostOnLinkedIn.module.scss';
import imageCompression from 'browser-image-compression';
import toast, { Toaster } from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from '@reach/router';

const LINK = `https://youtube.com/live/85SYLPYVHiU?feature=share`;

const descriptions = [
  `Thrilled to be attending CAN (Crio Alumni Network) Connect - Edition to inspire, an event organised by Crio.Do! \nWatch the live stream of the CAN Connect event here: ${LINK}\n\nCan't wait to meet new people, share unique experiences, and create meaningful connections. Looking forward to networking, and eager to see what opportunities arise from today’s get together. Time to network, learn, and be inspired! \n#CAN2024 #Networking #Inspiration #Crio`,
  `I'm incredibly excited to be part of CAN (Crio Alumni Network) Connect - Edition to inspire, an event organised by Crio.Do! \nWatch the live stream of the CAN Connect event here: ${LINK}\n\nVery excited to attend a talk on how AI is impacting the tech industry. It's always a pleasure to connect with like-minded individuals who share the same drive for success and innovation. Looking forward to networking, and eager to see what opportunities arise from today’s get together. Time to network, learn, and be inspired! \n#CAN2024 #Networking #Inspiration #Crio`,
  `I am here at CAN (Crio Alumni Network) Connect - Edition to inspire, an event organised by Crio.Do! Couldn't be more excited to become a part of this amazing community! \nWatch the live stream of the CAN Connect event here: ${LINK}\n\nLooking forward to making the most of this incredible networking opportunity and inspiring each other to reach new heights! \n#CAN2024 #Networking #Inspiration #Crio`,
];
//https://youtube.com/live/85SYLPYVHiU?feature=share

const getRandomDescription = () =>
  descriptions[Math.floor(Math.random() * descriptions.length)];

const PostOnLinkedIn = () => {
  const location = useLocation();

  const redirectUri = `${location.origin}/crio-alumni-network/share-linkedin/`;

  console.log('Redirect URL ', redirectUri);

  const clientId = '86favw8qg35ggx';
  const state = 'tudn6lynhc'; // Generate a random state string
  const scope = 'email%20openid%20profile%20w_member_social';

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [description, setDescription] = useState(getRandomDescription());
  const [isRotating, setIsRotating] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [postUrl, setPostUrl] = useState('');
  const [useFrontCamera, setUseFrontCamera] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const canvasRef = useRef(null);

  const data = useStaticQuery(graphql`
    query {
      cameraIcon: file(relativePath: { eq: "can/camera_icon.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 64
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      Background: file(relativePath: { eq: "can/Background.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bottomLeftDesign: file(relativePath: { eq: "can/bottom-design.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      topRightDesign: file(relativePath: { eq: "can/top-right-design.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      canMasterLogo: file(relativePath: { eq: "can/can-master-logo.svg" }) {
        publicURL
      }
      successIcon: file(relativePath: { eq: "can/success_icon.svg" }) {
        publicURL
      }
      reloadIcon: file(relativePath: { eq: "can/reload-icon.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const cameraIconImage = getImage(data.cameraIcon.childImageSharp);
  const background = getImage(data.Background.childImageSharp);
  const bottomLeftDesignImage = getImage(data.bottomLeftDesign.childImageSharp);
  const topRightDesignImage = getImage(data.topRightDesign.childImageSharp);
  const reloadIconImage = getImage(data.reloadIcon.childImageSharp);
  const canMasterLogoURL = data.canMasterLogo.publicURL;
  const successIconURL = data.successIcon.publicURL;

  useEffect(() => {
    setDescription(getRandomDescription());
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const stateReturned = urlParams.get('state');

    if (code && stateReturned === state) {
      setIsAuthorized(true);
    } else if (stateReturned && stateReturned !== state) {
      setStatusMessage('State mismatch error');
    }
  }, [state]);

  const handleReload = () => {
    setDescription(getRandomDescription());
    setIsRotating(true);
    setTimeout(() => setIsRotating(false), 500);
  };

  const handleLinkedInLogin = () => {
    const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&state=${state}&scope=${scope}`;
    window.location.href = authorizationUrl;
  };

  const handleCaptureChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const dataUrl = e.target.result;
      const compressedDataUrl = await compressImage(dataUrl);
      setPhoto(compressedDataUrl);
    };
    reader.readAsDataURL(file);
  };

  const handleSwitchCamera = () => {
    setUseFrontCamera(!useFrontCamera);
  };

  const isMobile = () => {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const compressImage = async (dataUrl) => {
    const file = dataURLtoFile(dataUrl, 'image.png');
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    return await fileToDataUrl(compressedFile);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!description.trim()) {
      toast('Please fill in the text area.', { icon: '⚠️' });
      setIsSubmitting(false);
      return;
    }
    if (!photo) {
      toast('Please take a photo.', { icon: '⚠️' });
      setIsSubmitting(false);
      return;
    }

    const blob = await fetch(photo).then((res) => res.blob());

    const formData = new FormData();
    formData.append('image', blob, 'image.png');
    formData.append('content', description);

    fetch(
      `https://linkedin-share-can.prod.crio.do/v1/linkedin/postwithimage?code=${code}&redirect_uri=${encodeURIComponent(
        redirectUri,
      )}`,
      {
        method: 'POST',
        body: formData,
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.postResponse && data.postResponse.postUrl) {
          setIsSubmitted(true);
          setPostUrl(data.postResponse.postUrl);
          setStatusMessage(
            `Successfully posted on LinkedIn! View your post [here](${data.postResponse.postUrl})`,
          );
          // toast.success('Successfully posted on LinkedIn!');
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          );
        } else {
          throw new Error(data.message || 'Posting failed');
        }
      })
      .catch((apiError) => {
        if (
          apiError.message === 'Token has been revoked, please re-authenticate'
        ) {
          handleLinkedInLogin();
        } else {
          console.error('API Error:', apiError);
          setStatusMessage(
            apiError.message || 'An error occurred during the API call',
          );
          toast.error(
            apiError.message || 'An error occurred during the API call',
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    let index = n;
    while (index--) {
      u8arr[index] = bstr.charCodeAt(index);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const fileToDataUrl = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  return (
    <div className={styles.container}>
      <Toaster />
      <div className={styles.bannerContainer}>
        <GatsbyImage
          image={background}
          alt="Background"
          className={styles.backgroundImage}
        />
        <GatsbyImage
          image={bottomLeftDesignImage}
          alt="Bottom Left Design"
          className={styles.bottomLeftDesign}
        />
        <GatsbyImage
          image={topRightDesignImage}
          alt="Top Right Design"
          className={styles.topRightDesign}
        />
        <img
          src={canMasterLogoURL}
          alt="CAN Connect Logo"
          className={styles.canMasterLogo}
        />
      </div>
      {!isSubmitted ? (
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <h1>Share Your CAN Connect Experience with Your Connections</h1>
            {isAuthorized ? (
              <>
                <div className={styles.textAreaContainer}>
                  <textarea
                    className={styles.textArea}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoFocus={true}
                    disabled={isSubmitting}
                  ></textarea>
                  <button
                    className={`${styles.reloadButton} ${
                      isRotating ? styles.rotate : ''
                    }`}
                    onClick={handleReload}
                    disabled={isSubmitting}
                  >
                    <GatsbyImage image={reloadIconImage} alt="Reload" />
                  </button>
                </div>
                {!photo ? (
                  <div className={styles.cameraContainer}>
                    <input
                      type="file"
                      accept="image/*"
                      capture={useFrontCamera ? 'user' : 'environment'}
                      onChange={handleCaptureChange}
                      className={styles.cameraInput}
                      id="cameraInput"
                      style={{ display: 'none' }}
                      disabled={isSubmitting}
                    />
                    <label htmlFor="cameraInput" className={styles.photoButton}>
                      <GatsbyImage
                        image={cameraIconImage}
                        style={{ width: '24px', height: '24px' }}
                        imgStyle={{ objectFit: 'contain' }}
                        alt="Take Photo"
                      />
                      <span>Take Photo</span>
                    </label>
                  </div>
                ) : (
                  <div className={styles.photoPreview}>
                    <img src={photo} alt="Captured" className={styles.photo} />
                    {!isSubmitting && (
                      <button
                        className={styles.retakeButton}
                        onClick={() => setPhoto(null)}
                        disabled={isSubmitting}
                      >
                        Retake Photo
                      </button>
                    )}
                  </div>
                )}
              </>
            ) : (
              <button
                className={styles.postButton}
                onClick={handleLinkedInLogin}
              >
                Authorize with LinkedIn
              </button>
            )}
            {isAuthorized && (
              <button
                className={styles.postButton}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Post on LinkedIn'
                )}
              </button>
            )}
          </div>
          <div className={styles.extraContent}></div>
        </div>
      ) : (
        <div className={styles.success}>
          <img src={successIconURL} alt="Success Icon" />
          <p>
            Thank you for sharing your experience of being at CAN Connect! Hope
            you have a great time networking with people and celebrating the
            success of fellow CAN members and Crio grads!
          </p>
          <a href={postUrl} target="_blank" rel="noopener noreferrer">
            <button className={styles.viewPostButton}>View Post</button>
          </a>
        </div>
      )}
      <canvas
        ref={canvasRef}
        style={{ display: 'none' }}
        width="640"
        height="480"
      ></canvas>
    </div>
  );
};

export default PostOnLinkedIn;
